import "./style.css";
import { Button, Form, Input, ConfigProvider, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { getUser, updatePassword, updateUser } from "../../../../API/user";
import PhoneInput from "react-phone-input-2";
import LocationModal from "../../../../Components/Common/LocationModal";
import { useForm } from "antd/es/form/Form";
import { ClipLoader } from "react-spinners";
import ImageUpload from "../../../../Components/Common/ImageUpload";
import { handleUpdateUser } from "../../../../Utils/UpdateUsersState";
import { UserContext } from "../../../../App";

const AccountDetails = () => {
  const [loadingDetails, setLoadingDetails] = useState(true);
  const { setUserData } = useContext(UserContext);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [user, setUser] = useState();
  const [locationValue, setLocationValue] = useState("");
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [form] = useForm();
  const [form2] = useForm();
  const [imageData, setImageData] = useState(null);
  const [imageObj, setImageObj] = useState(null);

  useEffect(() => {
    const func = async () => {
      const res = await getUser();
      if (res?.status === 200) {
        if (res?.data?.profile_photo) {
          setUser({
            ...res?.data,
            profile_photo:
              process.env.REACT_APP_BACKEND_PHOTO_URL +
              res?.data?.profile_photo,
          });
        } else {
          setUser(res?.data);
        }
        if (res?.data?.profile_photo) {
          setImageData(
            process.env.REACT_APP_BACKEND_PHOTO_URL + res?.data?.profile_photo
          );
        }
        setLocationValue(res?.data?.location);
      } else {
        api["error"]({
          message: t("fetchingFailed"),
          description: res?.response?.data?.detail,
        });
      }
      setLoadingDetails(false);
    };
    func();
  }, []);

  const handleLocationModal = () => {
    setOpenLocationModal(true);
  };

  const onFinishFailed = (values) => {
    console.error(values);
  };

  const onFinish = async (values) => {
    setLoadingDetails(true);
    const formData = new FormData();
    if (imageObj) {
      formData.append("profile_photo", imageObj, imageObj?.name);
    }

    formData.append("name", values.name);
    formData.append("location", values.location);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("username", values.username);
    const res = await updateUser(formData);
    if (res?.status === 200) {
      const newUser = res?.data;
      newUser.account = true;
      if (res?.data?.profile_photo) {
        newUser.profile_photo =
          process.env.REACT_APP_BACKEND_PHOTO_URL + res?.data?.profile_photo;
        setImageData(newUser.profile_photo);
      }
      setUser(newUser);
      handleUpdateUser(setUserData, newUser);
      api["success"]({
        message: t("updateSuccess"),
      });
    } else {
      api["error"]({
        message: t("updateFailed"),
        description: res?.response?.data?.detail,
      });
    }
    setLoadingDetails(false);
  };

  const onFinishPassword = async (values) => {
    setLoadingPassword(true);
    const res = await updatePassword(values);
    if (Math.floor(res?.status / 100) === 2) {
      api["success"]({
        message: t("updateSuccess"),
      });
      form2.resetFields();
    } else {
      api["error"]({
        message: t("updateFailed"),
        description: res?.response?.data?.detail,
      });
    }
    setLoadingPassword(false);
  };

  useEffect(() => {
    form.setFieldValue("location", locationValue);
  }, [locationValue]);

  return (
    <>
      {contextHolder}
      <div className="user-account-settings">
        <div className="account-details">
          <div className="left-container">
            <div className="section-title">{t("account-details")}</div>

            <div className="section-info">{t("updateProfileDetails")}</div>
          </div>
          <div className="right-container">
            <div className="form-container">
              <div className="form-title">{t("editDetails")}</div>
              {!loadingDetails && (
                <>
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={user}
                  >
                    <Form.Item label={t("profilePicture")} name="profile_photo">
                      <ImageUpload
                        imageData={imageData}
                        setImageData={setImageData}
                        setImageObj={setImageObj}
                      />
                    </Form.Item>
                    <div className="form-row">
                      <Form.Item
                        label={t("fullName")}
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t("fullNameWarning"),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={t("phone")}
                        style={{ marginBottom: "5px" }}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: t("phoneWarning"),
                          },
                        ]}
                      >
                        <PhoneInput
                          specialLabel={false}
                          className="phone-input"
                          placeholder="+852 XXXX XXXX"
                        />
                      </Form.Item>
                    </div>
                    <div className="form-row">
                      <Form.Item
                        label={t("username")}
                        name="username"
                        style={{ marginBottom: "5px" }}
                        rules={[
                          {
                            required: true,
                            message: t("usernameWarning"),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={t("email")}
                        name="email"
                        style={{ marginBottom: "5px" }}
                        rules={[
                          {
                            required: true,
                            message: t("emailWarning"),
                          },
                        ]}
                      >
                        <Input type={"email"} />
                      </Form.Item>
                    </div>
                    <Form.Item
                      label={t("location")}
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: t("locationWarning"),
                        },
                      ]}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Input
                          value={locationValue}
                          readOnly
                          placeholder={t("selectLocation")}
                          style={{ marginRight: "8px" }}
                        />
                        <Button
                          icon={<IoLocationSharp />}
                          onClick={handleLocationModal}
                        />
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <div className="form-footer">
                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: "#FAEF7C",
                              colorPrimaryHover: "#F9E64C",
                              colorPrimaryActive: "#E6D42A",
                            },
                            components: {
                              Button: {
                                defaultActiveBg: "#FAEF7C",
                                primaryColor: "#030303",
                              },
                            },
                          }}
                        >
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="submit-button"
                            disabled={loadingDetails}
                            loading={loadingDetails}
                            shape="round"
                          >
                            {t("save")}
                          </Button>
                        </ConfigProvider>
                      </div>
                    </Form.Item>
                  </Form>
                </>
              )}
              {loadingDetails && (
                <div className="full-screen-overlay">
                  <ClipLoader color={"#123abc"} size={50} />
                </div>
              )}
            </div>
          </div>
        </div>
        <hr
          style={{
            height: "2px",
            backgroundColor: "grey",
            border: "none",
          }}
        />
        <div className="password-and-security">
          <div className="left-container">
            <div className="section-title">{t("password&Secuirity")}</div>

            <div className="section-info">{t("AccountSurityMessage")}</div>
          </div>
          <div className="right-container">
            <div className="form-container-passwords">
              <div className="form-title">{t("changePassword")}</div>
              <Form
                form={form2}
                layout="vertical"
                onFinish={onFinishPassword}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label={t("currentPassword")}
                  name="old_pass"
                  rules={[
                    {
                      required: true,
                      message: t("passwordWarning"),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label={t("newPassword")}
                  name="new_pass"
                  rules={[
                    {
                      required: true,
                      message: t("passwordWarning"),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label={t("confirmPassword")}
                  name="confirmPassword"
                  dependencies={["password"]}
                  style={{ marginBottom: "20px" }}
                  rules={[
                    {
                      required: true,
                      message: t("passwordsDoNotMatch"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("new_pass") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The two passwords do not match")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <div className="form-footer">
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#FAEF7C",
                          colorPrimaryHover: "#F9E64C",
                          colorPrimaryActive: "#E6D42A",
                        },
                        components: {
                          Button: {
                            defaultActiveBg: "#FAEF7C",
                            primaryColor: "#030303",
                          },
                        },
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="submit-button"
                        disabled={loadingDetails}
                        loading={loadingDetails}
                        shape="round"
                      >
                        {t("updatePassword")}
                      </Button>
                    </ConfigProvider>
                  </div>
                </Form.Item>
              </Form>
              {loadingPassword && (
                <div className="full-screen-overlay">
                  <ClipLoader color={"#123abc"} size={50} />
                </div>
              )}
            </div>
          </div>
        </div>
        {openLocationModal && (
          <LocationModal
            open={openLocationModal}
            setOpen={setOpenLocationModal}
            setLocation={setLocationValue}
            location={locationValue}
          />
        )}
      </div>
    </>
  );
};

export default AccountDetails;
