import React, { useEffect, useRef, useState } from "react";
import Right from "../../../../Assets/Icons/right.svg";
import Left from "../../../../Assets/Icons/left.svg";
import "./style.css";
import LikeButton from "../../../../Components/Common/LikeButton";
import { useTranslation } from "react-i18next";
import ShopLogo from "../../../../Assets/Icons/shop.png";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const ShopsList = ({ title, url, icon, location = true }) => {
  const listRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showRightButton, setShowRightButton] = useState(false);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await url();
      if (res?.data) setData(res?.data);
      setLoading(false);
    };
    fetchData();
  }, [location]);

  useEffect(() => {
    const listContainer = listRef.current;
    if (listContainer) {
      const handleScroll = () => {
        setShowRightButton(
          listContainer.scrollWidth >
            listContainer.clientWidth + listContainer.scrollLeft
        );
        setShowLeftButton(listContainer.scrollLeft > 0);
      };

      listContainer.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => {
        listContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const scrollRight = () => {
    const listContainer = listRef.current;
    if (listContainer) {
      listContainer.scrollBy({ left: 325, behavior: "smooth" });
    }
  };

  const scrollLeft = () => {
    const listContainer = listRef.current;
    if (listContainer) {
      listContainer.scrollBy({ left: -350, behavior: "smooth" });
    }
  };

  return (
    <div className="shop-list">
      <div className="heading">
        {title}
        <img src={icon} alt={title} />
      </div>
      {!location ? (
        <div className="no-location">
          <p>{t("enableLocation")}</p>
        </div>
      ) : (
        <div className="list-container">
          <div className="list" ref={listRef}>
            {loading ? (
              <div className="loader-container">
                <div className="full-screen-overlay">
                  <ClipLoader color={"#123abc"} size={50} />
                </div>
              </div>
            ) : data?.length ? (
              data.map((item) => (
                <div key={item?.shop_id} className="item">
                  <div className="actions-menu-container">
                    <div className="actions-menu">
                      <div className="rating-container">
                        <div className="rating">{item?.rating}</div>
                      </div>
                      <div className="like-container">
                        <LikeButton
                          selectedInitialState={item?.liked}
                          id={item?.shop_id}
                        />
                      </div>
                    </div>
                    <img
                      src={
                        item?.photo
                          ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                            item?.photo
                          : ShopLogo
                      }
                      height="200px"
                      alt={item?.shop_name}
                      style={{ mixBlendMode: "multiply" }}
                    />
                  </div>
                  <div className="salon-info">
                    <p className="salon-name">{item?.shop_name}</p>
                    <p className="salon-subheading">{item?.address}</p>
                    <div
                      className="view-shop"
                      onClick={() => {
                        navigate("shop/" + item?.shop_id);
                      }}
                    >
                      <p>{t("view-shop")}</p>
                      <div className="arrow">
                        <img src={Right} alt="right" />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data">
                <p>{t("noData")}</p>
              </div>
            )}
          </div>
        </div>
      )}
      {showRightButton && (
        <img
          src={Right}
          alt="right"
          className="scroll-button right"
          onClick={scrollRight}
        />
      )}
      {showLeftButton && (
        <img
          src={Left}
          alt="left"
          className="scroll-button left"
          onClick={scrollLeft}
        />
      )}
    </div>
  );
};

export default ShopsList;
