import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LikeButton from "../../../../Components/Common/LikeButton";
import Right from "../../../../Assets/Icons/right.svg";
import "./style.css";
import { getFavourite } from "../../../../API/favourite";
import { ClipLoader } from "react-spinners";
import Shop from "../../../../Assets/Icons/shop.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../App";
import { handleUpdateUser } from "../../../../Utils/UpdateUsersState";

const Favourites = () => {
  const { t } = useTranslation();
  const { userData, setUserData } = useContext(UserContext);
  const [likedSalons, setLikedSalons] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      const res = await getFavourite();
      setLikedSalons(res?.data);
      setLoading(false);
    };
    func();
  }, []);

  return (
    <div className="favourites-container">
      <div className="favourites">
        <div className="text-section">
          <p className="heading">{t("likedSalons")}</p>
          <p className="text">
            {t("browseThroughTheSalonsYou'veChosenAsFavorites")}
          </p>
        </div>
        <div className="selection">
          <p className="subheading">{t("favourites")}</p>
          <div className="favourites">
            {loading ? (
              <div className="full-screen-overlay">
                <ClipLoader color={"#123abc"} size={50} />
              </div>
            ) : likedSalons.length ? (
              likedSalons.map((item, index) => (
                <div key={index} className="item">
                  <div className="actions-menu-container">
                    <div className="actions-menu">
                      <div className="rating-container">
                        <div className="rating">{item?.rating}</div>
                      </div>
                      <div className="like-container">
                        <LikeButton
                          selectedInitialState={item?.liked}
                          id={item?.shop_id}
                        />
                      </div>
                    </div>
                    <img
                      src={
                        item?.photo
                          ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                            item?.photo
                          : Shop
                      }
                      style={{ mixBlendMode: "multiply" }}
                      height="200px"
                      alt={item?.name}
                    />
                  </div>
                  <div className="salon-info">
                    <p className="salon-name">{item?.shop_name}</p>
                    <p className="salon-subheading">{item?.location}</p>
                    <div
                      className="view-shop"
                      onClick={() => {
                        handleUpdateUser(setUserData, {
                          ...userData,
                          account: false,
                        });
                        navigate("/shop/" + item?.shop_id);
                      }}
                    >
                      <p>{t("view-shop")}</p>
                      <div className="arrow">
                        <img src={Right} alt="right" />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data">
                <p>{t("favouritesDoNotExist")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Favourites;
