import React, { useContext, useState } from "react";
import "./style.css";
import Logo from "../../../../Assets/Images/betterLady.png";
import { Button, Checkbox, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import LanguageSelector from "../../../../Components/Common/LanguageSelector";
import { useTranslation } from "react-i18next";
import { login } from "../../../../API/auth";
import { encryptText } from "../../../../Utils/Encryption";
import updateUserStates from "../../../../Utils/UpdateUsersState";
import { UserContext } from "../../../../App";

const Signin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const { setUserData, setUserToken } = useContext(UserContext);

  const onFinish = async (values) => {
    setLoading(true);
    const loginData = await login(values);
    if (Math.floor(loginData?.status / 100) === 2) {
      localStorage.clear();
      localStorage.setItem(
        encryptText("token"),
        encryptText(
          JSON.stringify({
            access: loginData?.data?.access,
            refresh: loginData?.data?.refresh,
          })
        )
      );
      localStorage.setItem(
        encryptText("user"),
        encryptText(
          JSON.stringify({
            id: loginData?.data?.id,
            username: loginData?.data?.username,
            role: loginData?.data?.role,
            name: loginData?.data?.name,
            email: loginData?.data?.email,
            profile_photo: loginData?.data?.photo
              ? process.env.REACT_APP_BACKEND_PHOTO_URL + loginData?.data?.photo
              : null,
            account: false,
          })
        )
      );
      updateUserStates(setUserData, setUserToken);
      navigate("/home");
    } else {
      api["error"]({
        message: t("loginFailed"),
        description: loginData?.response?.data?.detail,
      });
    }
    setLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {contextHolder}
      <div className="signin">
        <LanguageSelector />
        <div className="container">
          <img
            src={Logo}
            alt="better-lady"
            width={"100px"}
            onClick={() => navigate("/")}
          />
          <p className="heading">{t("welcomeBack")}</p>
          <Form
            style={{
              width: 350,
              marginTop: "40px",
            }}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={t("email")}
              name="email"
              style={{ marginBottom: "5px" }}
              rules={[
                {
                  required: true,
                  message: t("emailWarning"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("password")}
              name="password"
              style={{ marginBottom: "5px" }}
              rules={[
                {
                  required: true,
                  message: t("passwordWarning"),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <div className="message-container j-space-between">
              <div className="message-container">
                <Checkbox />
                <p>{t("remember")}</p>
              </div>
              <a href="/dshbord">{t("forgotPassword")}</a>
            </div>

            <Form.Item>
              <div className="message-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                  disabled={loading}
                  loading={loading}
                >
                  {t("login")}
                </Button>
              </div>
              <div className="message-container footer">
                <p>{t("noAccount")}</p>
                <Button onClick={() => navigate("/signup")}>
                  {t("signup")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Signin;
