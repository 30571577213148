import { I18nextProvider } from "react-i18next";
import Routes from "./Routes";
import i18n from "./Utils/i18next";
import "./App.css";
import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { refresh } from "./API/auth";
import { decryptText, encryptText } from "./Utils/Encryption";
import updateUserStates, {
  getRefreshToken,
  handleLogout,
} from "./Utils/UpdateUsersState";

const UserContext = createContext();

function App() {
  const [userData, setUserData] = useState({
    username: "",
    role: "",
    id: "",
    email: "",
    name: "",
    account: false,
  });
  const [userToken, setUserToken] = useState({ access: "", refresh: "" });
  const [loading, setLoading] = useState(true);

  const refreshAccessToken = async (token) => {
    let myRefreshToken = token?.refresh;
    if (!myRefreshToken) {
      myRefreshToken = getRefreshToken();
    }
    const res = await refresh(myRefreshToken);
    if (res?.status === 200) {
      const localStorageItems = localStorage;
      if (localStorageItems.length !== 0) {
        for (let key in localStorageItems) {
          if (localStorageItems[key] && decryptText(key) === "token") {
            localStorage.removeItem(key);
          }
        }
      }
      localStorage.setItem(
        encryptText("token"),
        encryptText(
          JSON.stringify({
            access: res?.data?.access,
            refresh: res?.data?.refresh,
          })
        )
      );
      updateUserStates(setUserData, setUserToken);
      return res?.data?.access;
    } else {
      handleLogout(setUserData, setUserToken);
      window.location.href = "/login";
      return null;
    }
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        error?.response?.status === 401 &&
        error.config.url !==
          `${process.env.REACT_APP_BACKEND_URL}token/refresh/` &&
        error.config.url !== `${process.env.REACT_APP_BACKEND_URL}token/`
      ) {
        const newToken = await refreshAccessToken();

        if (newToken) {
          error.config.headers["Authorization"] = "Bearer " + newToken;
          return axios(error.config);
        } else {
          handleLogout(setUserData, setUserToken);
          window.location.href = "/login";
        }
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const initializeUserState = async () => {
      setLoading(true);
      const updatedStates = updateUserStates(setUserData, setUserToken);
      if (
        updatedStates?.token?.access &&
        updatedStates?.token?.refresh &&
        loading
      ) {
        await refreshAccessToken(updatedStates.token);
      }
      setLoading(false);
    };

    if (
      userData.role === "" &&
      userToken.access === "" &&
      userToken.refresh === "" &&
      loading
    ) {
      initializeUserState();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <UserContext.Provider
        value={{ userData, setUserData, userToken, setUserToken }}
      >
        <I18nextProvider i18n={i18n}>{!loading && <Routes />}</I18nextProvider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
export { UserContext };
