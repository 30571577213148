import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const getTags = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}shop/tag/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const createTag = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}shop/tag/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    console.log(res);
  } catch (e) {
    console.log(e);
    return e;
  }
};

const deleteTag = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}shop/tag/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { id },
      }
    );
    console.log(res);
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getPerk = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}shop/perk/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const createPerk = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}shop/perk/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    console.log(res);
  } catch (e) {
    console.log(e);
    return e;
  }
};

const deletePerk = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}shop/perk/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { id },
      }
    );
    console.log(res);
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getPhotos = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}shop/photo/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const uploadPhoto = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}shop/photo/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const deletePhoto = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}shop/photo/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getService = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}shop/service/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const createService = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}shop/service/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const deleteService = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}shop/service/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { id },
      }
    );
    console.log(res);
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getDescription = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}shop/description/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const createDescription = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}shop/description/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getRecommended = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}recommended/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const shopsNearby = async (location) => {
  if (!location) return null;
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}shops-nearby/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { location },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const discover = async (shuffle) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}discover/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { shuffle: new Date() },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const discoverUrl = async (url) => {
  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const savePerkandTags = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}shop/tag-perk/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export {
  getTags,
  createTag,
  deleteTag,
  getPerk,
  createPerk,
  deletePerk,
  getPhotos,
  uploadPhoto,
  deletePhoto,
  getService,
  createService,
  deleteService,
  getDescription,
  createDescription,
  getRecommended,
  discover,
  discoverUrl,
  savePerkandTags,
  shopsNearby,
};
