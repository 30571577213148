import React, { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import { UserContext } from "../App";
import PrivateRoutes from "./privateRoutes";
import PublicRoutes from "./publicRoutes";

const Routes = () => {
  const { userData } = useContext(UserContext);
  return (
    <BrowserRouter>
      {userData?.username ? <PrivateRoutes /> : <PublicRoutes />}
    </BrowserRouter>
  );
};

export default Routes;
