import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { PlusOutlined } from "@ant-design/icons";

const ServiceImageUpload = ({ imageData, setImageObj }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (imageData) {
      setFileList([{ uid: "-1", url: imageData, status: "done" }]);
      setImageObj(null);
    }
  }, [imageData]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList?.slice(0, 1));
    const file = newFileList?.[0];
    if (file?.originFileObj) {
      setImageObj(file.originFileObj);
    } else {
      setImageObj(null);
    }
  };

  const beforeUpload = (file) => {
    return false;
  };

  return (
    <ImgCrop rotationSlider>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        beforeUpload={beforeUpload}
      >
        {fileList.length < 1 && (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </Upload>
    </ImgCrop>
  );
};

export default ServiceImageUpload;
