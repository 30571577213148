import { PlusOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

const Staff = () => {
  const { t } = useTranslation();
  return (
    <div className="staff">
      <div className="heading-container">
        <p className="heading">{t("staffInformation")}</p>
        <Button className="add-button" icon={<PlusOutlined />}>
          {t("addStaff")}
        </Button>
      </div>
      <div>
        <Table bordered />
      </div>
    </div>
  );
};

export default Staff;
