import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "../locales/en.json";
import Chinese from "../locales/zh.json";

const resources = {
  en: {
    translation: English,
  },
  zh: {
    translation: Chinese,
  },
};

const language = localStorage.getItem("language");

i18n.use(initReactI18next).init({
  resources,
  lng: i18n.language,
  fallbackLng: language || "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
