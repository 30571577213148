import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Signin from "../Pages/Public/Auth/Signin";
import Signup from "../Pages/Public/Auth/SignUp";
import Discover from "../Pages/Public/Discover";
import Home from "../Pages/Public/Home";
import SearchComponent from "../Pages/Public/Search";
import Profile from "../Pages/Public/Profile";
import Shop from "../Pages/Public/Shop";

const PublicRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/discover/:id" element={<SearchComponent />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/shop/:id" element={<Shop />} />
        <Route path="/login" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </>
  );
};

export default PublicRoutes;
