import { HeartFilled } from "@ant-design/icons";
import { useState } from "react";
import { dislikeShop, likeShop } from "../../../API/favourite";
import "./style.css";

const LikeButton = ({
  selectedInitialState,
  id,
  setItems,
  items,
  disabled = false,
}) => {
  const [selected, setSelected] = useState(selectedInitialState);

  const handleChange = async () => {
    if (disabled) return;
    if (!selected) {
      await like();
    } else {
      await dislike();
    }
  };

  const like = async () => {
    setSelected(true);
    const res = await likeShop(id);
    if (Math.floor(res?.status / 100) !== 2) {
      setSelected(false);
    } else {
      setItems &&
        setItems(
          items.map((item) =>
            item.shop_id === id ? { ...item, num_fav: item.num_fav + 1 } : item
          )
        );
    }
  };

  const dislike = async () => {
    setSelected(false);
    const res = await dislikeShop(id);
    if (Math.floor(res?.status / 100) !== 2) {
      setSelected(true);
    } else {
      setItems &&
        setItems(
          items.map((item) =>
            item.shop_id === id ? { ...item, num_fav: item.num_fav - 1 } : item
          )
        );
    }
  };

  return (
    <div className="like" onClick={handleChange}>
      <HeartFilled className={`${selected ? "selected-" : ""}like-button`} />
    </div>
  );
};

export default LikeButton;
