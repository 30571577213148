import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ShopSettings from "../Pages/Private/ShopSettings";
import AccountSettings from "../Pages/Private/AccountSettings";
import { UserContext } from "../App";
import AccountDetails from "../Pages/Private/AccountSettings/AccountDetails";
import Preferences from "../Pages/Private/AccountSettings/Preferences";
import Favourites from "../Pages/Private/AccountSettings/Favourites";
import ShopDetails from "../Pages/Private/ShopSettings/ShopDetails";
import General from "../Pages/Private/ShopSettings/General";
import Staff from "../Pages/Private/ShopSettings/Staff";
import Home from "../Pages/Public/Home";
import Discover from "../Pages/Public/Discover";
import SearchComponent from "../Pages/Public/Search";
import Profile from "../Pages/Public/Profile";
import Shop from "../Pages/Public/Shop";

const PrivateRoutes = () => {
  const { userData } = useContext(UserContext);
  return (
    <>
      {userData.account ? (
        userData?.role === "user" ? (
          <AccountSettings>
            <Routes>
              <Route
                path="/account/settings/account-details"
                element={<AccountDetails />}
              />
              <Route
                path="/account/settings/preferences"
                element={<Preferences />}
              />
              <Route
                path="/account/settings/favourites"
                element={<Favourites />}
              />
              <Route
                path="*"
                element={<Navigate to="/account/settings/account-details" />}
              />
            </Routes>
          </AccountSettings>
        ) : (
          <ShopSettings>
            <Routes>
              <Route
                path="/shop/settings/shop-details"
                element={<ShopDetails />}
              />
              <Route path="/shop/settings/general" element={<General />} />
              <Route path="/shop/settings/add-staff" element={<Staff />} />
              <Route
                path="*"
                element={<Navigate to="/shop/settings/shop-details" />}
              />
            </Routes>
          </ShopSettings>
        )
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/discover/:id" element={<SearchComponent />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/shop/:id" element={<Shop />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      )}
    </>
  );
};

export default PrivateRoutes;
