import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const review = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}review/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const modifyReview = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}review/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { id },
      }
    );
  } catch (e) {
    console.log(e);
  }
};

const deleteReview = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}review/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const shopReviews = async (shop_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}shop-reviews/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { shop_id, page: 1 },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getReviewsURL = async (url) => {
  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export { review, modifyReview, deleteReview, shopReviews, getReviewsURL };
