import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../Components/Common/Footer";
import LikeButton from "../../../Components/Common/LikeButton";
import Navbar from "../../../Components/Layout/Navbar";
import "./style.css";
import Right from "../../../Assets/Icons/right.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile } from "../../../API/profile";
import { notification } from "antd";
import { ClipLoader } from "react-spinners";
import Shop from "../../../Assets/Icons/shop.png";
import ProfilePhoto from "../../../Assets/Images/profile.png";

const Profile = () => {
  const { t } = useTranslation();
  const profileId = useParams()?.id;
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getUserProfile(profileId);
      if (Math.floor(res?.status / 100) === 2) {
        setUser(res?.data);
      } else {
        api["error"]({
          message: t("fetchingFailed"),
          description: res?.response?.data?.detail,
        });
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      {contextHolder}
      <div className="profile">
        <Navbar title={t("publicProfileCustomers")} />
        <hr />
        {loading ? (
          <div className="full-screen-overlay">
            <ClipLoader color={"#123abc"} size={50} />
          </div>
        ) : user ? (
          <div className="customer-body">
            <div className="customer-details">
              <div className="image-container">
                <img
                  src={
                    user?.profile_photo
                      ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                        user?.profile_photo
                      : ProfilePhoto
                  }
                  alt="profiles"
                />
              </div>
              <div>
                <p className="heading">{user?.name}</p>
                <p className="text">{user?.username}</p>
                <p className="text">{user?.level}</p>
              </div>
            </div>
            <hr />
            <div className="recent">
              <div className="recent-reviews">
                <div>
                  <p className="heading">{t("recentReviews")}</p>
                  {user?.reviews?.length ? (
                    <div className="data-container">
                      {user?.reviews?.map((item, index) => {
                        return (
                          <div className="review-container" key={index}>
                            <div className="image-container">
                              <img
                                src={
                                  user?.profile_photo
                                    ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                                      user?.profile_photo
                                    : ProfilePhoto
                                }
                                alt="profiles"
                              />
                            </div>
                            <div>
                              <div className="name-text">
                                <p>{user?.name}</p>
                                <div className="rating-value">
                                  <p>{item?.overall_rating}</p>
                                </div>
                              </div>
                              <p className="review-body">{item?.remarks}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="no-data-wrapper">
                      <div className="no-data">
                        <p>{t("reviewsAppearHere")}</p>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <p className="heading">{t("favourites")}</p>
                  <div className="favourites">
                    {user?.favourites?.length ? (
                      <div className="data-container">
                        {user?.favourites?.map((item, index) => (
                          <div key={index} className="item">
                            <div className="actions-menu-container">
                              <div className="actions-menu">
                                <div className="rating-container">
                                  <div className="rating">{item?.rating}</div>
                                </div>
                                <div className="like-container">
                                  <LikeButton
                                    selectedInitialState={item?.liked}
                                    id={item?.shop_id}
                                  />
                                </div>
                              </div>
                              <img
                                className="image-container"
                                src={
                                  item?.photo
                                    ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                                      item?.photo
                                    : Shop
                                }
                                alt={item?.shop_name}
                              />
                            </div>
                            <div className="salon-info">
                              <p className="salon-name">{item?.shop_name}</p>
                              <p className="salon-subheading">
                                {item?.address}
                              </p>
                              <div
                                className="view-shop"
                                onClick={() => {
                                  navigate("/shop/" + item?.shop_id);
                                }}
                              >
                                <p>{t("view-shop")}</p>
                                <div className="arrow">
                                  <img src={Right} alt="right" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="no-data-wrapper">
                        <div className="no-data">
                          <p>{t("favouritesAppearHere")}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-data-wrapper-2">
            <div className="no-data">
              <p>{t("profileDoesNotExist")}</p>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default Profile;
