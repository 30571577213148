import React, { useEffect, useState } from "react";
import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import Uploadicon from "../../../../../Assets/Icons/upload.svg";
import { deletePhoto, uploadPhoto } from "../../../../../API/shop";
import { useTranslation } from "react-i18next";

const PhotoUpload = ({ imageData, id }) => {
  const [fileList, setFileList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (imageData) {
      setFileList([{ uid: "-1", url: imageData, status: "done" }]);
    }
  }, [imageData]);

  const onChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList?.slice(0, 1));
    const file = newFileList?.[0];

    if (file?.originFileObj) {
      const formData = new FormData();
      formData.append("photo", file.originFileObj, file.originFileObj?.name);
      formData.append("main", false);
      const res = await uploadPhoto(formData);
      if (res?.status === 201) {
        message.success(t("fileUploadedSuccessfully"));
      } else {
        message.error(t("fileUploadFailed"));
        setFileList([]);
      }
    } else if (newFileList.length === 0 && id) {
      const res = await deletePhoto(id);
      if (res?.status === 204) {
        message.success(t("fileDeleteSuccessfully"));
      } else {
        message.error(t("fileDeleteFailed"));
      }
    }
  };

  const beforeUpload = (file) => {
    return false;
  };

  return (
    <ImgCrop rotationSlider>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        beforeUpload={beforeUpload}
      >
        {fileList.length < 1 && (
          <p className="ant-upload-drag-icon">
            <img src={Uploadicon} alt="upload icon" />
          </p>
        )}
      </Upload>
    </ImgCrop>
  );
};

export default PhotoUpload;
