import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import ShopLogo from "../../../../Assets/Icons/shop.png";
import { ClipLoader } from "react-spinners";
import LikeButton from "../../../../Components/Common/LikeButton";
import { useNavigate } from "react-router-dom";

const Shops = ({
  items,
  setItems,
  fetchNextPageReviews,
  nextUrl,
  loading,
  loadingData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="shops-container">
      {loading ? (
        <div className="full-screen-overlay">
          <ClipLoader color={"#123abc"} size={50} />
        </div>
      ) : items?.length ? (
        <>
          <div className="shops">
            {items?.map((item) => (
              <div key={item?.shop_id} className="item">
                <img
                  onClick={() => {
                    navigate("/shop/" + item?.shop_id);
                  }}
                  src={
                    item?.main_photo
                      ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                        item?.main_photo
                      : ShopLogo
                  }
                  className="shop-photo"
                  alt={item?.shop_name}
                />
                <div className="salon-info">
                  <p className="salon-name">{item?.shop_name}</p>
                  <p className="salon-subheading">{item?.address}</p>
                </div>
                <div className="shop-data-items">
                  <div className="likes">
                    <LikeButton
                      id={item?.shop_id}
                      selectedInitialState={item?.liked}
                      setItems={setItems}
                      items={items}
                    />
                    {item?.num_fav}
                  </div>
                  <div className="rating"> {item?.rating}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="load-more-wrapper">
            {nextUrl && (
              <Button onClick={fetchNextPageReviews} loading={loadingData}>
                {t("loadMore")}
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="no-data">
          <p>{t("noData")}</p>
        </div>
      )}
    </div>
  );
};

export default Shops;
