import React, { useEffect, useState } from "react";
import Footer from "../../../Components/Common/Footer";
import Navbar from "../../../Components/Layout/Navbar";
import "./style.css";
import { Button, Form, Input, notification, Select } from "antd";
import { DollarOutlined, HomeFilled } from "@ant-design/icons";
import { AiTwotoneCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { getServiceList, search, searchUrl } from "../../../API/search";
import { useLocation, useNavigate } from "react-router-dom";
import Shop from "../../../Assets/Icons/shop.png";
import { ClipLoader } from "react-spinners";
import { MdWhatsapp } from "react-icons/md";
import { IoLogoInstagram } from "react-icons/io5";
import {
  getInstagramUsername,
  handleInstagram,
  handleWhatsapp,
} from "../../../Utils/Helper/socialActions";

const SearchComponent = () => {
  const { t } = useTranslation();
  const { Search } = Input;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [services, setServices] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [filters, setFilters] = useState({});
  const [searchText, setSearchText] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const rating = (number) => {
    if (number >= 0 && number < 5) {
      return t("belowAverage");
    } else if (number >= 5 && number < 7) {
      return t("aboveAverage");
    } else if (number >= 7 && number < 8) {
      return t("good");
    } else if (number >= 8) {
      return t("highlyRated");
    }
  };

  useEffect(() => {
    const filterValue = location?.state?.keywords
      ? location?.state
      : {
          keywords: decodeURIComponent(
            window?.location?.pathname.split("/")[2]
          ),
        };
    setFilters(filterValue);
    setSearchText(filterValue?.keywords);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingServices(true);
      const res = await getServiceList();
      setServices(res?.data);
      setLoadingServices(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await search(filters);
      setSearchText(filters?.keywords);
      setData(res?.data?.results);
      setNextUrl(res?.data?.next);
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  const handleSearch = async (e) => {
    setFilters({ ...filters, keywords: e });
    navigate("/discover/" + e, { replace: true, state: e });
  };

  const onFinish = (values) => {
    const numericValues = {
      ...values,
      min_price: values.min_price ? Number(values.min_price) : undefined,
      max_price: values.max_price ? Number(values.max_price) : undefined,
    };
    setFilters({ ...filters, ...numericValues });
  };

  const fetchNextPageSearch = async () => {
    setLoadingData(true);
    const res = await searchUrl(nextUrl, filters);
    if (Math.floor(res?.status / 100) === 2) {
      setData([...data, ...res?.data?.results]);
      setNextUrl(res?.data?.next);
    } else {
      api["error"]({
        message: t("fetchingFailed"),
        description: res?.response?.data?.detail,
      });
    }
    setLoadingData(false);
  };

  return (
    <>
      {contextHolder}
      <div className="search">
        <Navbar />
        <hr />
        <div className="header">
          <div className="text-container">
            <p className="message">
              {data?.total_results} {t("filteredResultsFor")}:{" "}
              {filters?.keywords}
            </p>
            <p className="search-text">'{filters?.keywords}'</p>
          </div>
          <Search
            placeholder={t("searchBeautyShops")}
            className="searchBar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onSearch={handleSearch}
          />
        </div>
        <hr />
        <div className="main-body">
          <div className="filters">
            <p className="filter-heading">{t("filters")}</p>
            <div>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item label={t("service")} name={"service_filter"}>
                  <Select
                    placeholder={t("selectService")}
                    options={services}
                    mode="multiple"
                    loading={loadingServices}
                  />
                </Form.Item>
                <Form.Item label={t("minimumPrice")} name={"min_price"}>
                  <Input
                    type={"number"}
                    prefix={<DollarOutlined />}
                    suffix={<AiTwotoneCloseCircle />}
                  />
                </Form.Item>
                <Form.Item label={t("maximumPrice")} name={"max_price"}>
                  <Input
                    type={"number"}
                    prefix={<DollarOutlined />}
                    suffix={<AiTwotoneCloseCircle />}
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className="submit-button">
                    {t("search")}
                  </Button>
                </Form.Item>
              </Form>
              <hr />
            </div>
          </div>
          {loading ? (
            <div className="full-screen-overlay">
              <ClipLoader color={"#123abc"} size={50} />
            </div>
          ) : data?.length ? (
            <div className="list">
              <div className="list-items">
                {data?.map((item) => (
                  <div className="item-card" key={item?.id}>
                    <img
                      src={
                        item?.profile_photo
                          ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                            item?.profile_photo
                          : Shop
                      }
                      alt={"profile"}
                      height={"175px"}
                      width={"175px"}
                    />
                    <div className="body-container">
                      <div className="name-container">
                        <div>
                          <p className="heading">{item?.name}</p>
                        </div>
                        <div className="name-container">
                          <div>
                            <p className="subheading">
                              {rating(item?.ratings?.overall_average)}
                            </p>
                            <p className="item-text">
                              {item?.reviews_count} {t("reviews")}
                            </p>
                          </div>
                          <div className="rating">
                            <p>{item?.ratings?.overall_average}</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        {item?.whatsapp && (
                          <div
                            className="shop-info"
                            onClick={() => handleWhatsapp(item?.whatsapp)}
                          >
                            <MdWhatsapp color="green" className="social-icon" />
                            <span>+{item?.whatsapp}</span>
                          </div>
                        )}
                        {item?.instagram && (
                          <div
                            className="shop-info"
                            onClick={() => handleInstagram(item?.instagram)}
                          >
                            <IoLogoInstagram
                              color="purple"
                              className="social-icon"
                            />
                            <span>
                              @{getInstagramUsername(item?.instagram)}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="values">
                        <HomeFilled />
                        <p className="item-text">{item?.address}</p>
                      </div>
                      <div className="name-container">
                        <div>
                          <p className="subheading">{t("servicesOffered")}</p>
                          <div className="services">
                            {item?.services?.map((service, index) => (
                              <div className="service" key={index}>
                                <p className="item-text">#{service?.title}</p>
                                <p className="item-price">${service?.price}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <Button
                          className="service-button"
                          onClick={() => navigate("/shop/" + item?.id)}
                        >
                          {t("seeServices")}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="load-more-wrapper">
                {nextUrl && (
                  <Button onClick={fetchNextPageSearch} loading={loadingData}>
                    {t("loadMore")}
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className="no-data">
              <p>{t("noShopsExist")}</p>
            </div>
          )}
        </div>

        <Footer />
      </div>
    </>
  );
};

export default SearchComponent;
