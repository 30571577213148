import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const likeShop = async (shop_id) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}like/`, {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: { shop_id },
    });
    return res;
  } catch (e) {
    console.log(e);
  }
};

const dislikeShop = async (shop_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}dislike/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { shop_id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const getFavourite = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}favourite/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export { likeShop, dislikeShop, getFavourite };
