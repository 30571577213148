import React, { useEffect, useState, useCallback } from "react";
import "./style.css";
import Logo from "../../../../Assets/Images/betterLady.png";
import { Button, Checkbox, Form, Input, notification, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageSelector from "../../../../Components/Common/LanguageSelector";
import { IoLocationSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import LocationModal from "../../../../Components/Common/LocationModal";
import { useForm } from "antd/es/form/Form";
import { register } from "../../../../API/auth";
import errorMessage from "../../../../Utils/Helper/errorMessage";
import PhoneInput from "react-phone-input-2";
import { checkAvailability } from "../../../../API/user";
import debounce from "lodash/debounce";

const Signup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [locationValue, setLocationValue] = useState("");
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [checked, setChecked] = useState(false);
  const [usernameStatus, setUsernameStatus] = useState(null);
  const [usernameDetail, setUsernameDetail] = useState("");

  const onFinish = async (values) => {
    values.offers = checked;
    setLoading(true);
    const res = await register(values);
    if (Math.floor(res?.status / 100) === 2) {
      navigate("/login");
    } else {
      api["error"]({
        message: t("unableToRegister"),
        description: errorMessage(res?.response?.data),
      });
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleLocationModal = () => {
    setOpenLocationModal(true);
  };

  useEffect(() => {
    form.setFieldValue("location", locationValue);
  }, [locationValue]);

  const checkUsernameAvailability = useCallback(
    debounce(async (username) => {
      try {
        const res = await checkAvailability(username);
        setUsernameStatus(res.data.available);
        setUsernameDetail(res.data.detail);
      } catch (error) {
        setUsernameStatus(null);
        setUsernameDetail("");
      }
    }, 300),
    []
  );

  const handleUsernameChange = (e) => {
    const username = e.target.value;
    if (username) {
      checkUsernameAvailability(username);
    } else {
      setUsernameStatus(null);
      setUsernameDetail("");
    }
  };

  return (
    <>
      {contextHolder}
      <div className="signup">
        <LanguageSelector />
        <div className="container">
          <img
            src={Logo}
            alt="better-lady"
            width={"100px"}
            onClick={() => navigate("/")}
          />
          <p className="heading">{t("welcome")}</p>
          <p className="description">{t("welcomeMessage")}</p>

          <Form
            form={form}
            style={{
              width: 350,
              marginBottom: "none",
            }}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div style={{ display: page === 1 ? "block" : "none" }}>
              <Form.Item
                label={t("register")}
                name="role"
                initialValue={location.state}
                rules={[
                  {
                    required: true,
                    message: t("registerWarning"),
                  },
                ]}
                style={{ marginBottom: "5px" }}
              >
                <Select
                  placeholder={t("selectType")}
                  options={[
                    {
                      value: "user",
                      label: t("customer"),
                    },
                    {
                      value: "shop",
                      label: t("shop"),
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={t("fullName")}
                name="name"
                style={{ marginBottom: "5px" }}
                rules={[
                  {
                    required: true,
                    message: t("fullNameWarning"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("email")}
                name="email"
                style={{ marginBottom: "5px" }}
                rules={[
                  {
                    required: true,
                    message: t("emailWarning"),
                  },
                ]}
              >
                <Input type={"email"} />
              </Form.Item>
              <Form.Item
                label={t("phone")}
                style={{ marginBottom: "5px" }}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t("phoneWarning"),
                  },
                ]}
              >
                <PhoneInput
                  specialLabel={false}
                  className="phone-input"
                  placeholder="+852 XXXX XXXX"
                />
              </Form.Item>
              <Form.Item
                label={t("location")}
                name="location"
                rules={[
                  {
                    required: true,
                    message: t("locationWarning"),
                  },
                ]}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    value={locationValue}
                    readOnly
                    placeholder={t("selectLocation")}
                    style={{ marginRight: "8px" }}
                  />
                  <Button
                    icon={<IoLocationSharp />}
                    onClick={handleLocationModal}
                  />
                </div>
              </Form.Item>
              <Button
                type="primary"
                onClick={() => {
                  setPage(2);
                }}
                className="submit-button"
              >
                {t("next")}
              </Button>
              <div className="footer">
                <p>{t("accountExistsMessage")}</p>
                <Button onClick={() => navigate("/login")}>{t("login")}</Button>
              </div>
            </div>

            <div style={{ display: page === 2 ? "block" : "none" }}>
              <Form.Item
                label={t("username")}
                name="username"
                style={{ marginBottom: "5px" }}
                rules={[
                  {
                    required: true,
                    message: t("usernameWarning"),
                  },
                ]}
              >
                <Input
                  onChange={handleUsernameChange}
                  className={
                    usernameStatus === null
                      ? ""
                      : usernameStatus
                      ? "input-available"
                      : "input-unavailable"
                  }
                />
              </Form.Item>
              {usernameStatus === false && (
                <div className="username-unavailable">{usernameDetail}</div>
              )}
              <Form.Item
                label={t("password")}
                name="password"
                style={{ marginBottom: "5px" }}
                rules={[
                  {
                    required: true,
                    message: t("passwordWarning"),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={t("confirmPassword")}
                name="confirmPassword"
                dependencies={["password"]}
                style={{ marginBottom: "20px" }}
                rules={[
                  {
                    required: true,
                    message: t("passwordsDoNotMatch"),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two passwords do not match")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div className="message-container">
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
                <p>{t("notification-confirmation")}</p>
              </div>
              <Form.Item>
                <div className="message-container">
                  <Button onClick={() => setPage(1)}>{t("back")}</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-button"
                    disabled={loading}
                    loading={loading}
                  >
                    {t("signup")}
                  </Button>
                </div>
                <div className="message-container footer">
                  <p className="confirmation-message">
                    {t("signupConfirmation")}
                  </p>
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
        <LocationModal
          open={openLocationModal}
          setOpen={setOpenLocationModal}
          setLocation={setLocationValue}
        />
      </div>
    </>
  );
};

export default Signup;
