import React, { useState } from "react";
import "./style.css";
import CouSync from "../../../Assets/Icons/cousync.jpeg";
import { useTranslation } from "react-i18next";
import ContactUsModal from "../ContactUsModal";

const Footer = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleContactModal = () => {
    setModalOpen(true);
  };

  return (
    <div className="footer">
      <hr />
      <div className="body">
        <div className="left">
          <div>
            <p className="title">BeautyHK</p>
            <p>{t("ultimateBeautyGuide2024")}</p>
          </div>
          <div
            className="link"
            onClick={() => window.open("https://www.cousync.com", "_blank")}
          >
            <p>{t("poweredBy")} </p>
            <img src={CouSync} alt="CouSync.com" />
          </div>
        </div>
        <div className="right">
          <p className="title">{t("help")}</p>
          <p>{t("faq")}</p>
          <p>{t("customer")}</p>
          <p>{t("howToGuide")}</p>
          <p className="clickable-option" onClick={handleContactModal}>
            {t("contactUs")}
          </p>
        </div>
      </div>
      {modalOpen && <ContactUsModal open={modalOpen} setOpen={setModalOpen} />}
    </div>
  );
};

export default Footer;
