import { Drawer, Layout, Menu } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import Logo from "../../../Assets/Images/betterLady.png";
import Navbar from "../../../Components/Layout/Navbar";

const AccountSettings = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);

  const items = [
    {
      key: "1",
      label: t("account-details"),
      onClick: () => {
        navigate("/account/settings/account-details");
      },
    },
    {
      key: "2",
      label: t("favourites"),
      onClick: () => {
        navigate("/account/settings/favourites");
      },
    },
  ];

  useEffect(() => {
    setSelectedKey(
      window.location.pathname?.includes("account-details")
        ? "1"
        : window.location.pathname?.includes("favourites")
        ? "2"
        : null
    );
  }, [window.location.pathname]);

  return (
    <div className="account-settings">
      <Layout style={{ height: "100vh" }}>
        <Header
          style={{
            backgroundColor: "white",
            height: "80px",
            padding: "0",
          }}
        >
          <Navbar title={t("accountSettings")} buttons={false} />
        </Header>
        <Layout>
          <Drawer
            className="drawer"
            title={false}
            placement="left"
            onClose={() => setVisible(false)}
            closable={false}
            open={visible}
            width={300}
          >
            <Layout>
              <Sider
                width={300}
                theme={"light"}
                className="sider"
                breakpoint={"lg"}
                collapsedWidth="0"
                trigger={null}
                backgroundColor="pink"
              >
                <div className="logo-container">
                  <img
                    src={Logo}
                    alt="Better Lady"
                    width={"100px"}
                    style={{ mixBlendMode: "multiply" }}
                  />
                </div>

                <Menu
                  mode="inline"
                  style={{
                    borderInlineEnd: "0px",
                    height: "100%",
                  }}
                  items={items}
                  defaultSelectedKeys={["1"]}
                  selectedKeys={[selectedKey]}
                />
              </Sider>
            </Layout>
          </Drawer>
          <Sider
            width={300}
            theme={"light"}
            className="sider"
            breakpoint={"lg"}
            collapsedWidth="0"
            trigger={null}
            style={{ backgroundColor: "#F9FAFB" }}
          >
            <div className="logo-container">
              <img
                src={Logo}
                alt="Better Lady"
                width={"100px"}
                style={{ mixBlendMode: "multiply" }}
              />
            </div>

            <Menu
              mode="inline"
              style={{
                height: "100%",
                borderInlineEnd: "0px",
                backgroundColor: "#F9FAFB",
              }}
              items={items}
              defaultSelectedKeys={["1"]}
              selectedKeys={[selectedKey]}
            />
          </Sider>
          <Layout>
            <Content style={{ backgroundColor: "white", overflowY: "auto" }}>
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default AccountSettings;
