import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const getUserProfile = async (user_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}profile/user/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { user_id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const getShopProfile = async (shop_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}profile/shop/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { shop_id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getShopServices = async (shop_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}profile/shop/services/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { shop_id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export { getShopProfile, getUserProfile, getShopServices };
