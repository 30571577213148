import { Button, Form, Input, Modal, notification, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { review } from "../../../API/rating";
import "./style.css";

const ReviewModal = ({ open, setOpen, shopId, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const { Option } = Select;

  const handleClose = () => {
    setOpen(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    values.shop_id = shopId;
    const res = await review(values);
    if (Math.floor(res?.status / 100) === 2) {
      setRefresh((prev) => !prev);
    } else {
      api["error"]({
        message: t("fetchingFailed"),
        description: res?.response?.data?.detail,
      });
    }
    setOpen(false);
    setLoading(false);
  };

  const onFinishFailed = (values) => {
    console.error(values);
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        onCancel={handleClose}
        onOk={handleClose}
        footer={false}
        className="review-modal"
      >
        <Form
          style={{
            width: 350,
            marginTop: "40px",
          }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t("remarks")}
            name="remarks"
            style={{ marginBottom: "5px" }}
            rules={[
              {
                required: true,
                message: t("remarksWarning"),
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <div className="double-items">
            <Form.Item
              label={t("service")}
              name="service"
              style={{ marginBottom: "5px" }}
              className="double-item"
              rules={[
                {
                  required: true,
                  message: t("selectWarning"),
                },
              ]}
            >
              <Select>
                {Array.from({ length: 10 }, (_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("cleanliness")}
              name="clean"
              style={{ marginBottom: "5px" }}
              className="double-item"
              rules={[
                {
                  required: true,
                  message: t("selectWarning"),
                },
              ]}
            >
              <Select>
                {Array.from({ length: 10 }, (_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="double-items">
            <Form.Item
              label={t("hospitality")}
              name="hospitality"
              style={{ marginBottom: "5px" }}
              className="double-item"
              rules={[
                {
                  required: true,
                  message: t("selectWarning"),
                },
              ]}
            >
              <Select>
                {Array.from({ length: 10 }, (_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("ambiance")}
              name="ambiance"
              style={{ marginBottom: "5px" }}
              className="double-item"
              rules={[
                {
                  required: true,
                  message: t("selectWarning"),
                },
              ]}
            >
              <Select>
                {Array.from({ length: 10 }, (_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            label={t("value")}
            name="value"
            style={{ marginBottom: "5px" }}
            rules={[
              {
                required: true,
                message: t("selectWarning"),
              },
            ]}
          >
            <Select>
              {Array.from({ length: 10 }, (_, i) => (
                <Option key={i + 1} value={i + 1}>
                  {i + 1}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              disabled={loading}
              loading={loading}
            >
              {t("saveReview")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ReviewModal;
