import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const search = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}search/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const searchUrl = async (url, data) => {
  try {
    const res = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getServiceList = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}service/list/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

export { search, getServiceList, searchUrl };
