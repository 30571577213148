import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const updateUser = async (data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}user/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getUser = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}user/`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const checkAvailability = async (username) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}availibility/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { username },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const updatePassword = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}user/pass/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const contact = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}contact/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export { updateUser, getUser, checkAvailability, updatePassword, contact };
