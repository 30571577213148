import { Button, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

const General = () => {
  const { t } = useTranslation();
  return (
    <div className="general">
      <hr />
      <div className="preference">
        <div className="text-section">
          <p className="heading">{t("preferences")}</p>
          <p className="text">
            {t(
              "customizeYourExperienceBySettingYourPreferencesToTailorYourInteractionsJustTheWayYouLike"
            )}
          </p>
        </div>
        <div className="selection">
          <div className="selection-item">
            <p className="subheading">{t("language")}</p>
            <Select />
          </div>
          <Button className="submit-button">{t("save")}</Button>
        </div>
      </div>
      <hr />
      <div className="preference">
        <div className="text-section">
          <p className="heading">{t("notifications")}</p>
          <p className="text">
            {t("stayInformedWithTailoredAlertsNeverMissABeat")}
          </p>
        </div>
        <div className="selection">
          <div className="selection-item">
            <p className="subheading">{t("allowNotifications")}</p>
            <Select />
          </div>
          <Button className="submit-button">{t("save")}</Button>
        </div>
      </div>
    </div>
  );
};

export default General;
