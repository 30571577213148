export const getInstagramUsername = (url) => {
  return url.split("/")[3];
};

export const handleInstagram = async (url) => {
  window.open(url, "_blank");
};

export const handleWhatsapp = async (number) => {
  window.open(`https://wa.me/${number}`, "_blank");
};
