import React, { useEffect, useState } from "react";
import { Button, ConfigProvider, Form, notification, Select } from "antd";
import { t } from "i18next";
import {
  getPerk,
  getPhotos,
  getTags,
  savePerkandTags,
} from "../../../../API/shop";
import { useForm } from "antd/es/form/Form";
import { ClipLoader } from "react-spinners";
import PhotoUpload from "./PhotoUpload";
import MainPhotoUpload from "./MainPhotoUpload";

const PhotosPerksTags = () => {
  const [shopInfo, setShopInfo] = useState({ perks: [], tags: [] });
  const [photos, setPhotos] = useState([]);
  const [mainPhoto, setMainPhoto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [form] = useForm();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      const perks = await getPerk();
      const tags = await getTags();
      const photosData = (await getPhotos())?.data;
      const photosList = photosData.filter((item) => !item?.main);
      const main = photosData.filter((item) => {
        return item?.main === true && item;
      });
      setPhotos(photosList);
      setMainPhoto(main[0]);
      setShopInfo({
        perks: perks?.data?.map((item) => item.name),
        tags: tags?.data?.map((item) => item.name),
      });
      setLoading(false);
    };
    func();
  }, []);

  const onFinishFailed = (values) => {
    console.error(values);
  };

  const onFinish = async (values) => {
    values.perks = values.perks || shopInfo.perks;
    values.tags = values.tags || shopInfo?.tags;
    setLoading(true);
    const res = await savePerkandTags(values);
    if (res?.status === 200) {
      api["success"]({
        message: t("updateSuccess"),
      });
    } else {
      api["error"]({
        message: t("updateFailed"),
        description: res?.response?.data?.detail,
      });
    }
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <div className="form-container">
        {loading ? (
          <div className="full-screen-overlay">
            <ClipLoader color={"#123abc"} size={50} />
          </div>
        ) : (
          <div className="form-container">
            <div className="form-title">{t("addPhotos")}</div>
            <div className="upload-photos">
              <div className="big-upload">
                <MainPhotoUpload
                  imageData={
                    mainPhoto?.photo
                      ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                        mainPhoto?.photo
                      : null
                  }
                  setImageObj={setMainPhoto}
                  id={mainPhoto?.id}
                />
              </div>

              <div className="small-uploads">
                <div className="small-uploads-row">
                  <div className="small-upload-btn">
                    <PhotoUpload
                      imageData={
                        photos[0]?.photo
                          ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                            photos[0]?.photo
                          : null
                      }
                      id={photos[0]?.id}
                    />
                  </div>

                  <div className="small-upload-btn">
                    <PhotoUpload
                      imageData={
                        photos[1]?.photo
                          ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                            photos[1]?.photo
                          : null
                      }
                      id={photos[1]?.id}
                    />
                  </div>
                </div>

                <div className="small-uploads-row">
                  <div className="small-upload-btn">
                    <PhotoUpload
                      imageData={
                        photos[2]?.photo
                          ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                            photos[2]?.photo
                          : null
                      }
                      id={photos[2]?.id}
                    />
                  </div>

                  <div className="small-upload-btn">
                    <PhotoUpload
                      imageData={
                        photos[3]?.photo
                          ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                            photos[3]?.photo
                          : null
                      }
                      id={photos[3]?.id}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-title">{t("perks")}</div>

            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item name="perks">
                <Select
                  mode="tags"
                  placeholder={t("selectPerks")}
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: "free_wifi",
                      label: t("freeWifi"),
                    },
                    {
                      value: "private_restroom",
                      label: t("privateRestrooms"),
                    },
                    {
                      value: "ac",
                      label: t("airConditioning"),
                    },
                  ]}
                  defaultValue={shopInfo.perks}
                  tagRender={(e) => (
                    <div className="tag">
                      <p className="tag-text">#{e?.label}</p>
                    </div>
                  )}
                />
              </Form.Item>
              <div className="form-title">{t("tags")}</div>
              <Form.Item name="tags">
                <Select
                  mode="tags"
                  placeholder={t("selectTags")}
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: "special_offer",
                      label: t("specialOffer"),
                    },
                    {
                      value: "popular",
                      label: t("popular"),
                    },
                    {
                      value: "common",
                      label: t("common"),
                    },
                  ]}
                  defaultValue={shopInfo.tags}
                  tagRender={(e) => (
                    <div className="tag">
                      <p className="tag-text">#{e?.label}</p>
                    </div>
                  )}
                />
              </Form.Item>
              <Form.Item>
                <div className="form-footer">
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#FAEF7C",
                        colorPrimaryHover: "#F9E64C",
                        colorPrimaryActive: "#E6D42A",
                      },
                      components: {
                        Button: {
                          defaultActiveBg: "#FAEF7C",
                          primaryColor: "#030303",
                        },
                      },
                    }}
                  >
                    <Button
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      className="submit-button"
                      disabled={loading}
                      loading={loading}
                    >
                      {t("save")}
                    </Button>
                  </ConfigProvider>
                </div>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </>
  );
};

export default PhotosPerksTags;
