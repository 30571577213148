import { Button, ConfigProvider, Form, Input, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createService, getService } from "../../../../API/shop";
import { useForm } from "antd/es/form/Form";
import { ClipLoader } from "react-spinners";
import ServiceImageUpload from "./ServiceImageUpload";
import { UserContext } from "../../../../App";

const Services = () => {
  const { t } = useTranslation();
  const { userData } = useContext(UserContext);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [imageData, setImageData] = useState(null);
  const [imageObj, setImageObj] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const func = async () => {
      setLoadingData(true);
      getServices();
      setLoadingData(false);
    };
    func();
  }, []);

  const getServices = async () => {
    const res = await getService();
    setServices(res?.data);
  };

  const onFinishFailed = (values) => {
    console.error(values);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const formData = new FormData();
    if (imageObj) {
      formData.append("photo", imageObj, imageObj?.name);
    }
    formData.append("title", values.title);
    formData.append("price", values.price);
    formData.append("description", values.additional_information);
    formData.append("shop", userData?.id);
    const res = await createService(formData);
    if (res?.status === 201) {
      api["success"]({
        message: t("updateSuccess"),
      });
      getServices();
    } else {
      api["error"]({
        message: t("updateFailed"),
        description: res?.response?.data?.detail,
      });
    }
    setLoading(false);
  };

  return (
    <div>
      {contextHolder}
      <div className="form-container">
        <div className="form-title">{t("existingServices")}</div>
        {loadingData ? (
          <div className="no-service-data">
            <p>{t("noData")}</p>
          </div>
        ) : (
          <div className="services-list">
            {services.map((item, index) => (
              <div className="service-card" key={index}>
                <img
                  src={
                    item?.photo
                      ? process.env.REACT_APP_BACKEND_PHOTO_URL + item?.photo
                      : "https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Test-Logo.svg/1200px-Test-Logo.svg.png"
                  }
                  alt="service"
                />
                <p className="textheading">{item?.title}</p>
                <div className="specifications">
                  <div className="info">
                    <p className="subtext">{item?.description}</p>
                  </div>
                </div>
                <Button className="review-button">${item?.price}</Button>
              </div>
            ))}
          </div>
        )}
        <div className="form-title">{t("addService")}</div>
        {loading ? (
          <div className="full-screen-overlay">
            <ClipLoader color={"#123abc"} size={50} />
          </div>
        ) : (
          <>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              style={{ marginTop: "20px" }}
            >
              <Form.Item label={t("servicePhoto")} name="profile_photo">
                <div className="big-upload">
                  <ServiceImageUpload
                    imageData={imageData}
                    setImageData={setImageData}
                    setImageObj={setImageObj}
                  />
                </div>
              </Form.Item>
              <div className="form-row">
                <Form.Item
                  name={"title"}
                  label={t("title")}
                  rules={[
                    {
                      required: true,
                      message: t("titleWarning"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={"price"}
                  label={t("price")}
                  rules={[
                    {
                      required: true,
                      message: t("priceWarning"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <Form.Item
                name={"additional_information"}
                label={t("additionalInformation")}
                rules={[
                  {
                    required: true,
                    message: t("additionalInformationWarning"),
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item>
                <div className="form-footer">
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#FAEF7C",
                        colorPrimaryHover: "#F9E64C",
                        colorPrimaryActive: "#E6D42A",
                      },
                      components: {
                        Button: {
                          defaultActiveBg: "#FAEF7C",
                          primaryColor: "#030303",
                        },
                      },
                    }}
                  >
                    <Button
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      className="submit-button"
                      disabled={loading}
                      loading={loading}
                    >
                      {t("save")}
                    </Button>
                  </ConfigProvider>
                </div>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default Services;
